<template>
	<div class="gamification d-flex flex-column">
		<div class="gamification_about-section p-0">
			<TitlePage
				class="mb-2"
				:title="$t('gamification.title')"
			>
				<IconSax name="cup" />
			</TitlePage>

			<div class="gamification_about-section_info d-flex flex-column flex-md-row gap-2">
				<div class="p-0 col-12 col-md-8">
					<TextPlay
						color="var(--text-color)"
						class="mb-3"
						:text="$t('gamification.introduction.congratulations')"
					/>
					<TextPlay
						color="var(--text-color)"
						v-html="$t('gamification.introduction.about_config', { link: 'https://faq.voompcreators.com.br/' })"
					/>
				</div>

				<ButtonPlay
					v-if="getIsGamificationActive"
					icon="trash"
					type="feedback-error"
					:text="$t('gamification.deactivate_gamification')"
					@click="$bvModal.show('deactiveGamificationModal')"
				/>
				<DeactivateModal />
			</div>
		</div>

		<DividerPlay class="mt-4 mb-3" />

		<TabsPlay
			:selectedTab="selectedTab"
			@selectTab="selectedTab = $event"
			:enableAllTabs="getIsGamificationActive"
			:tabs="[
				{ icon: 'ranking-1', label: $t('gamification.ranking_points') },
				{ icon: 'buy-crypto', label: $t('gamification.medals') },
				{ icon: 'discount-shape', label: $t('gamification.coupons') },
			]"
		>
			<template slot="0">
				<RankingConfiguration @goNext="selectedTab = 1" />
			</template>

			<template slot="1">
				<MedalsConfiguration @goNext="selectedTab = 2" />
			</template>

			<template slot="2">
				<CouponConfiguration />
			</template>
		</TabsPlay>
	</div>
</template>

<script>
import TitlePage from "@/components/common/TitlePage.vue";
import RankingConfiguration from "@/components/RankingConfiguration.vue";
import MedalsConfiguration from "@/components/MedalsConfiguration.vue";
import CouponConfiguration from "@/components/CouponConfiguration.vue";
import DeactivateModal from "./DeactivateGamificationModal.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		TitlePage,
		RankingConfiguration,
		MedalsConfiguration,
		CouponConfiguration,
		DeactivateModal,
	},
	data() {
		return {
			selectedTab: 0,
			saveLoading: false,
		};
	},
	computed: {
		...mapGetters({ getIsGamificationActive: "config/getIsGamificationActive" }),
	},
};
</script>

<style lang="scss">
.gamification {
	&_about-section {
		a {
			color: var(--maincolor);
		}
	}
}
</style>

<style lang="scss" scoped>
.gamification {
	padding: 64px 40px;

	&_about-section {
		&_info {
			align-items: center;
			justify-content: space-between;
		}
	}

	&_divider {
		border-color: var(--bordercolor) !important;
		border-bottom: 1px solid;
	}
}

@media (max-width: 768px) {
	.gamification {
		padding: 32px 16px;

		&_about-section {
			&_info {
				align-items: start;
				justify-content: space-between;

				a {
					color: var(--maincolor);
				}
			}
		}
	}
}
</style>
